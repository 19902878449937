import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
// import { localization } from './localization';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
  }

  // get the Version of Browser, older than '11'
  getIEVersion() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }
    const trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      const rv = ua.indexOf('rv:');
      return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }
    const edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }
    // other browser
    return false;
  }

  closeModal() {
    window.location.reload();
  }

  render() {
    const iEVersion = this.getIEVersion();
    const { selectedLanguage, children, languageData } = this.props;
    // const lang = localization[selectedLanguage] || localization.en;
    const lang = languageData && languageData[selectedLanguage] || languageData.en;


    if (iEVersion && iEVersion < 10) {
      return (
        <ModalWrapper id="modal">
          <div className="modal-content">
            <span onClick={e => this.closeModal()} className="close">
              &times;
            </span>
            <div>
               <img
                src="/images/img_nodatafound.svg"
                alt="No data found"
                width="301"
                height="227"
              />
              <p>{lang.page_not_load_msg}</p>
              <div className="update-browser" id="updateBrowser">
              <p
                dangerouslySetInnerHTML={{
                  __html: lang.browser_suggest_meesage,
                }}
              />
              </div>
            </div>
          </div>
        </ModalWrapper>
      );
    }

    if (this.state.hasError) {
      return (
        <ModalWrapper id="modal">
          <div className="modal-content">
            <div>
             <img
              src="/images/img_nodatafound.svg"
              alt="No data found"
              width="301"
              height="227"
            />
                <p>{lang.page_not_load_msg} {lang.try_again}</p>
              <button onClick={e => this.closeModal()}>{lang.reload}</button>
            </div>
          </div>
        </ModalWrapper>
      );
    }
    return children;
  }
}

const mapStateToProps = state => ({
  selectedLanguage: state.localization.selectedLanguage,
  languageData: state.localization.languageData
});

export default connect(mapStateToProps)(ErrorBoundary);

const ModalWrapper = styled.div`
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  padding-top: 100px;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: #5B86E5;

  /* Modal Content */
  .modal-content {
    color: #fff;
    font: normal 13px/1.5 'Work Sans', sans-serif;
    margin: auto;
    padding: 50px;
    max-width: 60vw;
    background: #fff;
    border: 0;
    padding: 60px;
    border-radius: 4px;
    text-align: center;
    a {
      color: #3abaa8;
    }
    a:hover {
      text-decoration: underline;
    }
    p {
      color: #34324C;
      font: italic 17px/26px 'Lato', sans-serif;
      margin: 0 auto;
      width: 80%;
    }
    > div > p:first-of-type {
      margin: 40px auto;
    }
    img {
      width: 100%;
    }
    button {
      background-color: #3abaa8;
      border: 0;
      border-radius: 24px;
      color: #fff;
      font-size: 14px;
      height: 48px;
      width: 302px;
      text-transform: uppercase;
    }
    button:hover {
      background-color: #34a796;
    }
    @media all and (min-width: 767px) {
      max-width: 500px;
    }
    @media all and (min-width: 1400px) {
      img {
        width: initial;
      }
    }
  }

  /* The Close Button */
  .close {
    color: #fff;
    float: right;
    font-size: 22px;
    font-weight: bold;
    width: 30px;
    line-height: 30px;
    border-radius: 50%;
    text-align: center;
    font-size: 22px;
  }

  .close:hover,
  .close:focus {
    background: rgba(255, 255, 255, 0.1);
    text-decoration: none;
    cursor: pointer;
  }

  .update-browser p {
    font-style: normal;
    font-size: 14px;
    color: #aaa;
    line-height: 22px;
    margin-top: -10px;
  }
`;
