import { combineReducers } from 'redux';
import overview from './layout/overview/overviewReducer';
import hr from './layout/hr/hrReducer';
import finance from './layout/finance/financeReducer';
import project from './layout/project/projectReducer';
import filter from './component/Header/filterReducer';
import localization from './localization/localizationReducer';

export default combineReducers({
  overview,
  hr,
  finance,
  project,
  filter,
  localization,
});
