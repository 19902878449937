import React from 'react';
import styled from 'styled-components';

const StatsLoading = () => {
  return (
    <StatsLoadingStyled>
      <div className="loader-bg">
        <div className="loader">
          <div className="loader__bar"></div>
          <div className="loader__bar"></div>
          <div className="loader__bar"></div>
          <div className="loader__bar"></div>
          <div className="loader__bar"></div>
          <div className="loader__ball"></div>
        </div>
      </div>
    </StatsLoadingStyled>
  );
};

const StatsLoadingStyled = styled.div``;

export default StatsLoading;
