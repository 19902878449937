import localization from './local.json'
const getLanguage = () => {
  const { hostname } = window.location;

  if (hostname.includes('ngo-openboek')) {
    return 'nl';
  }

  if (hostname.includes('ong-livreouvert')) {
    return 'fr';
  }

  return 'en';
};

const initialState = {
  selectedLanguage: localStorage.getItem('selectedLanguage') || getLanguage(),
  languageData: {...localization}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LANGUAGE':
      localStorage.setItem('selectedLanguage', action.payload); //persist for reload
      return { ...state, selectedLanguage: action.payload }

    case 'SET_LANGUAGE_CONTENT':
      return {...state,languageData:action.payload}

    default:
      return state;
  }
};
