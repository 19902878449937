const initialState = {
  stats: {},
  hrCardStats: {},
  mapData: [],
  hrData: null,
  genderDistrib: [],
  workTimeDistrib: [],
  ageGroupDistrib: [],
  ageGroupTypeDistrib: [],
  ngoData: [],
  countryData: [],

  statsLoading: true,
  hrCardStatsLoading: true,
  mapDataLoading: true,
  hrDataLoading: true,
  genderDistribLoading: true,
  workTimeDistribLoading: true,
  ageGroupDistribLoading: true,
  ageGroupTypeDistribLoading: true,
  ngoDataLoading: true,
  countryDataLoading: true,

  statsError: false,
  hrCardStatsError: false,
  mapDataError: false,
  hrDataError: false,
  genderDistribError: false,
  workTimeDistribError: false,
  ageGroupDistribError: false,
  ageGroupTypeDistribError: false,
  ngoDataError: false,
  countryDataError: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'HR_STATS_FETCHING':
      return { ...state, statsLoading: true };
    case 'HR_STATS_FETCHED':
      return {
        ...state,
        stats: action.payload,
        statsLoading: false,
        statsError: false,
      };
    case 'HR_STATS_ERROR':
      return { ...state, statsError: true, statsLoading: false };

    case 'HR_CARD_STATS_FETCHING':
      return { ...state, hrCardStatsLoading: true };
    case 'HR_CARD_STATS_FETCHED':
      return {
        ...state,
        hrCardStats: action.payload,
        hrCardStatsLoading: false,
        hrCardStatsError: false,
      };
    case 'HR_CARD_STATS_ERROR':
      return { ...state, hrCardStatsError: true, hrCardStatsLoading: false };

    case 'HR_MAPDATA_FETCHING':
      return { ...state, mapDataLoading: true, mapData: [] };
    case 'HR_MAPDATA_FETCHED':
      return {
        ...state,
        mapData: action.payload,
        mapDataLoading: false,
        mapError: false,
      };
    case 'HR_MAPDATA_ERROR':
      return { ...state, mapError: true, mapDataLoading: false };

    case 'HRDATA_FETCHING':
      return { ...state, hrDataLoading: true };
    case 'HRDATA_FETCHED':
      return {
        ...state,
        hrData: action.payload,
        hrDataLoading: false,
        hrDataError: false,
      };
    case 'HRDATA_ERROR':
      return { ...state, hrDataError: true, hrDataLoading: false };

    case 'GENDER_DISTRIB_FETCHING':
      return { ...state, genderDistribLoading: true };
    case 'GENDER_DISTRIB_FETCHED':
      return {
        ...state,
        genderDistrib: action.payload,
        genderDistribLoading: false,
        genderDistribError: false,
      };
    case 'GENDER_DISTRIB_ERROR':
      return {
        ...state,
        genderDistribError: true,
        genderDistribLoading: false,
      };

    case 'WORK_TIME_DISTRIB_FETCHING':
      return { ...state, workTimeDistribLoading: true };
    case 'WORK_TIME_DISTRIB_FETCHED':
      return {
        ...state,
        workTimeDistrib: action.payload,
        workTimeDistribLoading: false,
        workTimeDistribError: false,
      };
    case 'WORK_TIME_DISTRIB_ERROR':
      return {
        ...state,
        workTimeDistribError: true,
        workTimeDistribLoading: false,
      };

    case 'AGE_GROUP_DISTRIB_FETCHING':
      return { ...state, ageGroupDistribLoading: true };
    case 'AGE_GROUP_DISTRIB_FETCHED':
      return {
        ...state,
        ageGroupDistrib: action.payload,
        ageGroupDistribLoading: false,
        ageGroupDistribError: false,
      };
    case 'AGE_GROUP_DISTRIB_ERROR':
      return {
        ...state,
        ageGroupDistribError: true,
        ageGroupDistribLoading: false,
      };

    case 'AGE_GROUP_TYPE_DISTRIB_FETCHING':
      return { ...state, ageGroupTypeDistribLoading: true };
    case 'AGE_GROUP_TYPE_DISTRIB_FETCHED':
      return {
        ...state,
        ageGroupTypeDistrib: action.payload,
        ageGroupTypeDistribLoading: false,
        ageGroupTypeDistribError: false,
      };
    case 'AGE_GROUP_TYPE_DISTRIB_ERROR':
      return {
        ...state,
        ageGroupTypeDistribError: true,
        ageGroupTypeDistribLoading: false,
      };

    case 'COUNTRYDATA_FETCHING':
      return { ...state, countryDataLoading: true };
    case 'COUNTRYDATA_FETCHED':
      return {
        ...state,
        countryData: action.payload,
        countryDataLoading: false,
        countryDataError: false,
      };
    case 'COUNTRYDATA_ERROR':
      return { ...state, countryDataError: true, countryDataLoading: false };

    case 'NGODATA_FETCHING':
      return { ...state, ngoDataLoading: true };
    case 'NGODATA_FETCHED':
      return {
        ...state,
        ngoData: action.payload,
        ngoDataLoading: false,
        ngoDataError: false,
      };
    case 'NGODATA_ERROR':
      return { ...state, ngoDataError: true, ngoDataLoading: false };

    default:
      return state;
  }
};
