import React from 'react';
import styled from 'styled-components';

class GDPR extends React.Component {
  constructor() {
    super();
    this.state = {
      gdpr: false,
    };
  }
  render() {
    if (this.state.gdpr) {
      return null;
    }
    return (
      <GDPRWrapper>
        <div className="container">
          <div className="gdpr-block">
            <p>
              We use first-party cookies to enhance this web by saving the
              preferred language. If you continue browsing, we assume that you
              accept it. If you would like to know more, read our{' '}
              <a href="/privacy-policy">privacy policy</a>.
            </p>
            <button
              onClick={() => {
                this.setState({ gdpr: true });
                localStorage.setItem('gdpr', true);
              }}
            >
              I agree
            </button>
          </div>
        </div>
      </GDPRWrapper>
    );
  }
}

export default GDPR;

const GDPRWrapper = styled.div`
  background: #fff;
  bottom: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  font-size: 14px;
  left: 0;
  line-height: 24px;
  padding: 16px;
  position: fixed;
  width: 100%;
  z-index: 100000;
  .gdpr-block p {
    display: inline;
    margin-right: 6px;
  }
  button {
    background: transparent;
    border: 1px solid #3abaa8;
    border-radius: 20px;
    color: #3abaa8;
    padding: 4px 8px;
    transition: all 0.3s ease-in-out;
    white-space: nowrap;
    &:hover {
      background: #3abaa8;
      color: #fff;
    }
  }
  a {
    border-bottom: 1px solid transparent;
    color: #37baa8;
    &:hover {
      border-color: #37baa8;
    }
  }
`;
