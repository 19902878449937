const initialState = {
  stats: {},
  mapData: [],
  countryData: [],
  ngoData: [],
  sectorData: [],
  ngoInfo: {},
  hrData: null,
  expenditureData: [],
  pcVsBelgiumData: null,

  statsLoading: true,
  mapLoading: true,
  countryLoading: true,
  ngoLoading: true,
  sectorLoading: true,
  hrLoading: true,
  expenditureLoading: true,
  pcVsBelgiumDataLoading: true,
  ngoInfoLoading: true,

  statsError: false,
  mapError: false,
  countryError: false,
  ngoError: false,
  sectorError: false,
  hrError: false,
  expenditureError: false,
  pcVsBelgiumDataError: false,
  ngoInfoError: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'STATS_FETCHING':
      return { ...state, statsLoading: true };
    case 'STATS_FETCHED':
      return {
        ...state,
        stats: action.payload,
        statsLoading: false,
        statsError: false,
      };
    case 'STATS_ERROR':
      return { ...state, statsError: true, statsLoading: false };

    case 'MAPDATA_FETCHING':
      return { ...state, mapLoading: true, mapData: [] };
    case 'MAPDATA_FETCHED':
      return {
        ...state,
        mapData: action.payload,
        mapLoading: false,
        mapError: false,
      };
    case 'MAPDATA_ERROR':
      return { ...state, mapError: true, mapLoading: false };

    case 'COUNTRYDATA_FETCHING':
      return { ...state, countryLoading: true };
    case 'COUNTRYDATA_FETCHED':
      return {
        ...state,
        countryData: action.payload,
        countryLoading: false,
        countryError: false,
      };
    case 'COUNTRYDATA_ERROR':
      return { ...state, countryError: true, countryLoading: false };

    case 'NGODATA_FETCHING':
      return { ...state, ngoLoading: true, ngoData: [] };
    case 'NGODATA_FETCHED':
      return {
        ...state,
        ngoData: action.payload,
        ngoLoading: false,
        ngoError: false,
      };
    case 'NGODATA_ERROR':
      return { ...state, ngoError: true, ngoLoading: false };

    case 'SECTORDATA_FETCHING':
      return { ...state, sectorLoading: true };
    case 'SECTORDATA_FETCHED':
      return {
        ...state,
        sectorData: action.payload,
        sectorLoading: false,
        sectorError: false,
      };
    case 'SECTORDATA_ERROR':
      return { ...state, sectorError: true, sectorLoading: false };

    case 'HRDATA_FETCHING':
      return { ...state, hrLoading: true };
    case 'HRDATA_FETCHED':
      return {
        ...state,
        hrData: action.payload,
        hrLoading: false,
        hrError: false,
      };
    case 'HRDATA_ERROR':
      return { ...state, hrError: true, hrLoading: false };

    case 'EXPENDITUREDATA_FETCHING':
      return { ...state, expenditureLoading: true };
    case 'EXPENDITUREDATA_FETCHED':
      return {
        ...state,
        expenditureData: action.payload,
        expenditureLoading: false,
        expenditureError: false,
      };
    case 'EXPENDITUREDATA_ERROR':
      return { ...state, expenditureError: true, expenditureLoading: false };

    case 'PCVSBELGIUM_FETCHING':
      return { ...state, pcVsBelgiumDataLoading: true };
    case 'PCVSBELGIUM_FETCHED':
      return {
        ...state,
        pcVsBelgiumData: action.payload,
        pcVsBelgiumDataLoading: false,
        pcVsBelgiumDataError: false,
      };
    case 'PCVSBELGIUM_ERROR':
      return {
        ...state,
        pcVsBelgiumDataError: true,
        pcVsBelgiumDataLoading: false,
      };

    case 'NGO_INFO_FETCHING': {
      return { ...state, ngoInfoLoading: true };
    }
    case 'NGO_INFO_FETCHED':
      return {
        ...state,
        ngoInfo: action.payload,
        ngoInfoLoading: false,
        ngoInfoError: false,
      };
    case 'NGO_INFO_ERROR':
      return { ...state, ngoInfoError: true, ngoInfoLoading: false };
    default:
      return state;
  }
};
