const initialState = {
  activeYear: null,
  ngoList: [],
  countryList: [],
  sectorList: [],
  selectedNgo: null,
  selectedCountry: null,
  selectedSector: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'ACTIVE_YEAR':
      return { ...state, activeYear: action.payload };
    case 'FILTER':
      return {
        ...state,
        ngoList: action.payload.ngo || [],
        countryList: action.payload.country || [],
        sectorList: action.payload.sector || [],
      };
    case 'NGO_FILTER':
      return { ...state, ngoList: action.payload };
    case 'COUNTRY_FILTER':
      return { ...state, countryList: action.payload };

    case 'SECTOR_FILTER':
      return { ...state, sectorList: action.payload };

    case 'SELECT_NGO':
      return { ...state, selectedNgo: action.payload };
    case 'SELECT_COUNTRY':
      return { ...state, selectedCountry: action.payload };
    case 'SELECT_SECTOR':
      return { ...state, selectedSector: action.payload };
    case 'RESET_FILTER':
      return {
        ...state,
        selectedSector: null,
        selectedCountry: null,
        selectedNgo: null,
      };

    default:
      return state;
  }
};
