const initialState = {
  stats: {},
  mapData: [],
  ngoData: [],
  countryData: [],
  pcVsBelgiumData: null,
  expenditureByType: [],
  resourceData: [],
  grantData: [],
  ownVsGrantTrendData: null,
  ownVsGrantBarData: [],
  ownResourcesTrendData: null,
  grantTrendData: null,
  incomeStatement: {},
  bilan: {},
  structuralVsHumanitarian: [],
  pdfLink : null,

  statsLoading: true,
  mapDataLoading: true,
  ngoDataLoading: true,
  countryDataLoading: true,
  pcVsBelgiumDataLoading: true,
  expenditureByTypeLoading: true,
  resourceDataLoading: true,
  grantDataLoading: true,
  ownVsGrantTrendDataLoading: true,
  ownVsGrantBarDataLoading: true,
  ownResourcesTrendDataLoading: true,
  grantTrendDataLoading: true,
  incomeStatementLoading: true,
  bilanLoading: true,
  structuralVsHumanitarianLoading: true,

  statsError: false,
  mapDataError: false,
  ngoDataError: false,
  countryDataError: false,
  pcVsBelgiumDataError: false,
  expenditureByTypeError: false,
  resourceDataError: false,
  grantDataError: false,
  ownVsGrantTrendDataError: false,
  ownVsGrantBarDataError: false,
  ownResourcesTrendDataError: false,
  grantTrendDataError: false,
  incomeStatementError: false,
  bilanError: false,
  structuralVsHumanitarianError: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'FINANCE_STATS_FETCHING':
      return { ...state, statsLoading: true };
    case 'FINANCE_STATS_FETCHED':
      return {
        ...state,
        stats: action.payload,
        statsLoading: false,
        statsError: false,
      };
    case 'FINANCE_STATS_ERROR':
      return { ...state, statsError: true, statsLoading: false };

    case 'FINANCE_MAPDATA_FETCHING':
      return { ...state, mapDataLoading: true, mapData: [] };
    case 'FINANCE_MAPDATA_FETCHED':
      return {
        ...state,
        mapData: action.payload,
        mapDataLoading: false,
        mapDataError: false,
      };
    case 'FINANCE_MAPDATA_ERROR':
      return { ...state, mapError: true, mapDataLoading: false };

    case 'COUNTRYDATA_FETCHING':
      return { ...state, countryDataLoading: true };
    case 'COUNTRYDATA_FETCHED':
      return {
        ...state,
        countryData: action.payload,
        countryDataLoading: false,
        countryDataError: false,
      };
    case 'COUNTRYDATA_ERROR':
      return { ...state, countryDataError: true, countryDataLoading: false };

    case 'NGODATA_FETCHING':
      return { ...state, ngoDataLoading: true };
    case 'NGODATA_FETCHED':
      return {
        ...state,
        ngoData: action.payload,
        ngoDataLoading: false,
        ngoDataError: false,
      };
    case 'NGODATA_ERROR':
      return { ...state, ngoDataError: true, ngoDataLoading: false };

    case 'PCVSBELGIUM_FETCHING':
      return { ...state, pcVsBelgiumDataLoading: true };
    case 'PCVSBELGIUM_FETCHED':
      return {
        ...state,
        pcVsBelgiumData: action.payload,
        pcVsBelgiumDataLoading: false,
        pcVsBelgiumDataError: false,
      };
    case 'PCVSBELGIUM_ERROR':
      return {
        ...state,
        pcVsBelgiumDataError: true,
        pcVsBelgiumDataLoading: false,
      };

    case 'EXPENDITURE_BY_TYPE_FETCHING':
      return { ...state, expenditureByTypeLoading: true };
    case 'EXPENDITURE_BY_TYPE_FETCHED':
      return {
        ...state,
        expenditureByType: action.payload,
        expenditureByTypeLoading: false,
        expenditureByTypeError: false,
      };
    case 'EXPENDITURE_BY_TYPE_ERROR':
      return {
        ...state,
        expenditureByTypeError: true,
        expenditureByTypeLoading: false,
      };

    case 'RESOURCE_FETCHING':
      return { ...state, resourceDataLoading: true };
    case 'RESOURCE_FETCHED':
      return {
        ...state,
        resourceData: action.payload,
        resourceDataLoading: false,
        resourceDataError: false,
      };
    case 'RESOURCE_ERROR':
      return { ...state, resourceDataError: true, resourceDataLoading: false };

    case 'GRANT_FETCHING':
      return { ...state, grantDataLoading: true };
    case 'GRANT_FETCHED':
      return {
        ...state,
        grantData: action.payload,
        grantDataLoading: false,
        grantDataError: false,
      };
    case 'GRANT_ERROR':
      return { ...state, grantDataError: true, grantDataLoading: false };

    case 'OWN_VS_GRANT_TREND_FETCHING':
      return { ...state, ownVsGrantTrendDataLoading: true };
    case 'OWN_VS_GRANT_TREND_FETCHED':
      return {
        ...state,
        ownVsGrantTrendData: action.payload,
        ownVsGrantTrendDataLoading: false,
        ownVsGrantTrendDataError: false,
      };
    case 'OWN_VS_GRANT_TREND_ERROR':
      return {
        ...state,
        ownVsGrantTrendDataError: true,
        ownVsGrantTrendDataLoading: false,
      };

    case 'OWN_VS_GRANT_BAR_FETCHING':
      return { ...state, ownVsGrantBarDataLoading: true };
    case 'OWN_VS_GRANT_BAR_FETCHED':
      return {
        ...state,
        ownVsGrantBarData: action.payload,
        ownVsGrantBarDataLoading: false,
        ownVsGrantBarDataError: false,
      };
    case 'OWN_VS_GRANT_BAR_ERROR':
      return {
        ...state,
        ownVsGrantBarDataError: true,
        ownVsGrantBarDataLoading: false,
      };

    case 'OWN_RESOURCES_TREND_FETCHING':
      return { ...state, ownResourcesTrendDataLoading: true };
    case 'OWN_RESOURCES_TREND_FETCHED':
      return {
        ...state,
        ownResourcesTrendData: action.payload,
        ownResourcesTrendDataLoading: false,
        ownResourcesTrendDataError: false,
      };
    case 'OWN_RESOURCES_TREND_ERROR':
      return {
        ...state,
        ownResourcesTrendDataError: true,
        ownResourcesTrendDataLoading: false,
      };

    case 'GRANT_TREND_FETCHING':
      return { ...state, grantTrendDataLoading: true };
    case 'GRANT_TREND_FETCHED':
      return {
        ...state,
        grantTrendData: action.payload,
        grantTrendDataLoading: false,
        grantTrendDataError: false,
      };
    case 'GRANT_TREND_ERROR':
      return {
        ...state,
        grantTrendDataError: true,
        grantTrendDataLoading: false,
      };

    case 'INCOME_STATEMENT_FETCHING':
      return { ...state, incomeStatementLoading: true };
    case 'INCOME_STATEMENT_FETCHED':
      return {
        ...state,
        incomeStatement: action.payload,
        incomeStatementLoading: false,
        incomeStatementError: false,
      };
    case 'INCOME_STATEMENT_ERROR':
      return {
        ...state,
        incomeStatementError: true,
        incomeStatementLoading: false,
      };

    case 'BILAN_FETCHING':
      return { ...state, bilanLoading: true };
    case 'BILAN_FETCHED':
      return {
        ...state,
        bilan: action.payload,
        bilanLoading: false,
        bilanError: false,
      };
    case 'BILAN_ERROR':
      return { ...state, bilanError: true, bilanLoading: false };

    case 'STRUCTURAL_VS_HUMANITARIAN_FETCHING':
      return { ...state, structuralVsHumanitarianLoading: true };
    case 'STRUCTURAL_VS_HUMANITARIAN_FETCHED':
      return {
        ...state,
        structuralVsHumanitarian: action.payload,
        structuralVsHumanitarianLoading: false,
        structuralVsHumanitarianError: false,
      };
    case 'STRUCTURAL_VS_HUMANITARIAN_ERROR':
      return {
        ...state,
        structuralVsHumanitarianError: true,
        structuralVsHumanitarianLoading: false,
      };
    
    case 'PDF_LINK':
      return {
        ...state,
        pdfLink: action.payload,
      }

    default:
      return state;
  }
};
