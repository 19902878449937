import './polyfill';
import React, { Suspense, lazy, useEffect } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import store from './store';
import ErrorBoundary from './ErrorBoundary';

import StatsLoading from './component/StatsLoading';
import GDPR from './gdpr';
import './assets/sass/app.scss';

const Home = lazy(() => import('./layout/home'));
const About = lazy(() => import('./layout/about'));
const DataQuality = lazy(() => import('./layout/dataQuality'));
const Contact = lazy(() => import('./layout/contact'));
const PrivacyPolicy = lazy(() => import('./layout/privacyPolicy'));

const App = () => {
  localStorage.removeItem('lang');
  localStorage.removeItem('language');
  const gdpr = localStorage.getItem('gdpr');
  
  return (
    <Provider store={store}>
      <ErrorBoundary>
        <Router>
          <Suspense fallback={<StatsLoading />}>
            <Switch>
              <Route path="/about" exact component={About} />
              <Route path="/data-quality" exact component={DataQuality} />
              <Route path="/contact" exact component={Contact} />
              <Route path="/privacy-policy" exact component={PrivacyPolicy} />
              <Route path="/" render={props => <Home {...props} />} />
            </Switch>
          </Suspense>
        </Router>
        {!gdpr && <GDPR />}
      </ErrorBoundary>
    </Provider>
  );
};

render(<App />, document.getElementById('root'));
