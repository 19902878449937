const initialState = {
  stats: {},
  mapData: [],
  projectList: [],
  projectDetail: {
    narrative: {},
    general: {},
    expenditureVsBudget: [],
  },
  budgetDistribution: [],
  partnerList: {
    financialOrgList: [],
    nonFinancialOrgList: [],
  },
  results: [],

  statsLoading: true,
  mapLoading: true,
  projectListLoading: true,
  projectDetailLoading: true,
  budgetDistributionLoading: true,
  partnerListLoading: true,
  resultsLoading: true,

  statsError: false,
  mapError: false,
  projectListError: false,
  projectDetailError: false,
  budgetDistributionError: false,
  partnerListError: false,
  resultsError: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'STATS_FETCHING':
      return { ...state, statsLoading: true };
    case 'STATS_FETCHED':
      return { ...state, stats: action.payload, statsLoading: false };
    case 'STATS_ERROR':
      return { ...state, statsError: true };

    case 'MAPDATA_FETCHING':
      return { ...state, mapLoading: true, mapData: [] };
    case 'MAPDATA_FETCHED':
      return { ...state, mapData: action.payload, mapLoading: false };
    case 'MAPDATA_ERROR':
      return { ...state, mapError: true };

    case 'PROJECT_LIST_FETCHING':
      return { ...state, projectListLoading: true };
    case 'PROJECT_LIST_FETCHED':
      return {
        ...state,
        projectList: action.payload,
        projectListLoading: false,
        projectListError: false,
      };
    case 'PROJECT_LIST_ERROR':
      return { ...state, projectListError: true, projectListLoading: false };

    case 'PROJECT_DETAIL_FETCHING':
      return { ...state, projectDetailLoading: true };
    case 'PROJECT_DETAIL_FETCHED':
      return {
        ...state,
        projectDetail: action.payload,
        projectDetailLoading: false,
        projectDetailError: false,
      };
    case 'PROJECT_DETAIL_ERROR':
      return {
        ...state,
        projectDetailError: true,
        projectDetailLoading: false,
      };

    case 'BUDGET_DISTRIBUTION_FETCHING':
      return { ...state, budgetDistributionLoading: true };
    case 'BUDGET_DISTRIBUTION_FETCHED':
      return {
        ...state,
        budgetDistribution: action.payload,
        budgetDistributionLoading: false,
        budgetDistributionError: false,
      };
    case 'BUDGET_DISTRIBUTION_ERROR':
      return {
        ...state,
        budgetDistributionError: true,
        budgetDistributionLoading: false,
      };

    case 'PARTNER_LIST_FETCHING':
      return { ...state, partnerListLoading: true };
    case 'PARTNER_LIST_FETCHED':
      return {
        ...state,
        partnerList: action.payload,
        partnerListLoading: false,
        partnerListError: false,
      };
    case 'PARTNER_LIST_ERROR':
      return { ...state, partnerListError: true, partnerListLoading: false };

    case 'RESULT_FETCHING':
      return { ...state, resultsLoading: true };
    case 'RESULT_FETCHED':
      return {
        ...state,
        results: action.payload,
        resultsLoading: false,
        resultsError: false,
      };
    case 'RESULT_ERROR':
      return { ...state, resultsError: true, resultsLoading: false };

    default:
      return state;
  }
};
